import { HiOutlineExternalLink } from "react-icons/hi";
import React from 'react'

export default function PortfolioCard({ title, description, imageUrl, link }) {
  return (
    <div className="group relative shadow-custom overflow-hidden rounded-lg transition-all duration-300 hover:shadow-xl">
      <img
        src={imageUrl}
        alt={title}
        className="h-64 w-full object-cover object-center transition-all duration-300 group-hover:scale-110"
      />
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-70"></div>
      <div className="absolute flex flex-col bottom-4 left-0 right-0 p-6 transition-all duration-300 translate-y-6 group-hover:translate-y-0">
        
        <p className="text-white mb-4  overflow-hidden  opacity-0 transition-opacity duration-300  group-hover:opacity-100">{description}</p>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-3 mr-auto bg-slate_blue text-white font-semibold py-2 px-4 rounded transition-all duration-300 hover:bg-opacity-90"
        >
            <HiOutlineExternalLink  className='text-white text-xl'/>
           
          {title}
         
        </a>
      </div>
    </div>
  )
}