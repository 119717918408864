import { Route, Routes } from "react-router-dom";

import Home from "../screens/home";
import Portfolio from "../screens/portfolio";
import Project from "../screens/project";
import { Toaster } from "react-hot-toast";

const NotFound = () => {
  return (
    <div className="flex h-full w-full  items-center justify-center text-3xl font-bold">
      Not Found!
    </div>
  );
};

export function AppNavigation() {
  return (
    <div className="h-full">
        <Toaster/>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path=":projectId" element={<Project />} />
      

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}
