import React, { useState } from "react";

import { MdEmail } from "react-icons/md";

const Email = () => {
  const [email, setEmail] = useState("");
  return (
    // <div className="mt-20 px-4 py-2 bg-white shadow-custom flex flex-row gap-2 items-center rounded-full  mx-auto">
    //   <MdEmail className="text-2xl" />
    //   <input
    //     value={email}
    //     onChange={(e) => setEmail(e.target.value)}
    //     placeholder="Enter your email"
    //     className="flex-grow outline-none "
    //   />
      <div onClick={()=>window.location.href = "mailto:info@whizzta.com"} className="cursor-pointer w-[130px] mx-auto mt-20 min-h-[40px] shadow-lg bg-slate_blue text-white flex items-center justify-center rounded-full">
        Get In Touch
      </div>
    // </div>
  );
};

export default Email;
