import { collection, getDocs, query } from "firebase/firestore";
import { useEffect, useState } from "react";

import Footer from "../components/footer";
import PortfolioComp from "../components/portfolio";
import { db } from "../utils/firebaseConfig";
import { useNavigate } from "react-router-dom";

function Portfolio() {
  const [projects, setProjects] = useState([]);
  const navigate=useNavigate()
  const getProjects = async () => {
    const projectsQuery = query(collection(db, "portfolio"));

    const projectsSnapshot = await getDocs(projectsQuery);

    const projectsTemp = [];
    projectsSnapshot.forEach(async (project) => {
      projectsTemp.push({ id: project.id, ...project.data() });
    });
    console.log("----", projectsTemp);
    setProjects(projectsTemp);
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="flex flex-col scroll-smooth">
      {/* Hero */}
      <div
        id="home"
        className="relative flex items-center justify-center min-h-[500px] "
      >
        {/* Colored circles */}
        <div className="absolute w-48 h-48 bg-slate_blue rounded-full left-20 top-20 opacity-70"></div>
        <div className="absolute w-48 h-48 bg-orange rounded-full right-20 bottom-20 opacity-70"></div>

        {/* Glassmorphism box */}
        <div className="relative  w-full flex flex-col  min-h-[500px] mx-auto bg-white/50   backdrop-blur-2xl ">
          <div className="w-full flex flex-col sm:flex-row items-center justify-between min-h-[100px] p-4 mx-auto max-w-[1440px]">
            <img className="w-36 hidden sm:block" src="/assets/logo.png" />
            <div className="flex flex-row items-center gap-7 ">
              <a
                href="/#home"
                className="font-light decoration-none text-[16px]"
              >
                Home
              </a>
              <a
                href="/#services"
                className="font-light decoration-none text-[16px]"
              >
                Services
              </a>
              <h1
              onClick={()=>navigate("/portfolio")}
                
                className="font-light decoration-none text-[16px] cursor-pointer"
              >
                Portfolio
              </h1>
              <a
                href="/#contact"
                className="font-light decoration-none text-[16px]"
              >
                Contact Us
              </a>
            </div>
            <div className="w-36" />
          </div>
          {/* Hero */}
          <div className="mx-6 flex flex-col gap-3">
            <div className="w-full mx-6 grid grid-cols-2 ">
              <div className="flex flex-col gap-5 justify-center">
                <h1 className="font-bold text-xl">Our Work</h1>
                <h2 className="font-semibold">
                  Whizzta is renowned for its 360-degree custom AI, web, and
                  mobile app solutions.
                </h2>
                <p>
                  Our custom AI, web, and mobile app solutions are showcased
                  below. At Whizzta, we dedicate top-tier resources to ensure
                  our projects drive client satisfaction and business success.
                  Our innovative designs are powered by out-of-the-box thinking
                  and cutting-edge technology, setting a new standard across the
                  digital landscape.
                </p>

                <div className="p-3 bg-slate_blue rounded-lg mr-auto font-semibold text-white">
                  Request a quote
                </div>
              </div>
              <img
                className="object-contain aspect-video"
                src="/assets/intro.svg"
              />
            </div>
            
          </div>
        </div>
      </div>

      <div className="my-10 bg-sky-100 px-5 py-5">
              <div className="my-5 flex items-center justify-center">
                <h1 className="text-3xl font-bold">Our Portfolio</h1>
              </div>
              <PortfolioComp />
            </div>

            <Footer/>
    </div>
  );
}

export default Portfolio;
