import { collection, getDocs, query } from "firebase/firestore";
import { useEffect, useState } from "react";

import PortfolioCard from "./portfolio-card";
import Slider from "./slider";
import { db } from "../utils/firebaseConfig";

const PortfolioComp =  () => {
  
  const [projects, setProjects] = useState([])
  const getProjects=async()=>{



  const projectsQuery = query(collection(db, "portfolio"));

  const projectsSnapshot = await getDocs(projectsQuery);

  const projectsTemp = [];
  projectsSnapshot.forEach(async (project) => {
    projectsTemp.push({ id: project.id, ...project.data() });
  });
  setProjects(projectsTemp)
  }
  
  useEffect(() => {
    getProjects()
  }, [])
  

  return <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 gap-y-12 ">



{projects.map((project,index)=><div className=" w-ful">
<PortfolioCard 
link={project?.url}
key={index}
title={project?.name}
description={project?.description} 
imageUrl={project?.image} />
</div>)}
  </div>;
};

export default PortfolioComp;
