// Import the functions you need from the SDKs you need
import { getApps, initializeApp } from "firebase/app";

import {getFirestore} from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDwjKl1voQNgvEQ5d9l1uEUuj0HKj97Y8w",
    authDomain: "whizzta-7dbd5.firebaseapp.com",
    projectId: "whizzta-7dbd5",
    storageBucket: "whizzta-7dbd5.appspot.com",
    messagingSenderId: "223779280182",
    appId: "1:223779280182:web:c91a75bb7a471acbd00f97",
    measurementId: "G-M441YKBN3H"
  };

// Initialize Firebase
const app =  initializeApp(firebaseConfig)




export const db = getFirestore(app);